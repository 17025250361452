.landing-page {
  height: 100vh;
  background-image: url('../../assets/images/background.jpg');
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 600px;
  padding: 20px;
}

.ghost-image {
  width: 200px;
  height: 200px;
  background-image: url('../../assets/images/ghost-logo/Ghost_Complete.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin-bottom: 30px;
}

.menu {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.menu h1 {
  font-size: 3em;
  color: white;
  font-family: "Permanent Marker", cursive;
}

@media (max-width: 768px) {
  .ghost-image {
    width: 100px;
    height: 100px;
    margin-bottom: 20px;
  }
}