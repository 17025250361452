/* src/components/MerchPage/MerchPage.css */
.merch-page {
  background-color: black;
  color: white;
  font-family: "Righteous", sans-serif;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.content {
  flex: 1;
  padding: 20px;
  padding-top: 80px; /* Adjust based on your navbar height */
}

h1 {
  margin-bottom: 0px;
  font-size: 2.5em;
  text-transform: uppercase;
  letter-spacing: 2px;
  text-align: center;
}

.collection-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.collection-card {
  height: 200px;
  background-size: cover;
  background-position: center;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.collection-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 5px 15px rgba(0,0,0,0.3);
}

.collection-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0,0,0,0.7);
  color: white;
  padding: 10px;
}

.collection-overlay h3 {
  margin: 0;
  font-size: 1.2em;
}

.signup-flag {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #ff0000;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  font-family: "Righteous", sans-serif;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  z-index: 100;
  font-weight: bold;
  box-shadow: 0 2px 5px rgba(0,0,0,0.2);
}

.signup-flag:hover {
  transform: translateY(-3px);
  box-shadow: 0 4px 8px rgba(0,0,0,0.3);
}

.signup-flag span {
  display: block;
}

.footer {
  display: none;
}

@media (min-width: 768px) {
  .collection-grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .collection-card {
    height: 300px;
  }

  .footer {
    display: block;
  }
}

@media (min-width: 1024px) {
  .collection-grid {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  }
}