/* src/components/Footer/Footer.css */
.footer {
    background-color: #111;
    color: white;
    padding: 20px 0;
    text-align: center;
    opacity: 0.8;
  }
  
  .social-icons {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .social-icon-link {
    margin: 0 10px;
  }
  
  .social-icon {
    width: 32px;
    height: 32px;
    transition: transform 0.3s ease;
  }
  
  .social-icon:hover {
    transform: scale(1.1);
  }
  
  .copyright {
    font-size: 0.9em;
    opacity: 0.7;
  }
  
  @media (min-width: 768px) {
    .social-icon {
      width: 48px;
      height: 48px;
    }
  }
  
  @media (min-width: 1200px) {
    .social-icon {
      width: 64px;
      height: 64px;
    }
  }

  @media (max-width: 767px) {
    .footer {
      display: none;
    }
  }