/* src/components/ItemPage/ItemPage.css */
.item-page {
    background-color: black;
    color: white;
    font-family: "Righteous", sans-serif;
    min-height: 100vh;
    display:flex;
    flex-direction: column;
  }
  
  .content {
    padding: 20px;
    padding-top: 80px; /* Adjust this value based on your navbar height */
    text-align: center;
    padding-bottom: 60px; /* Adjust this value based on your footer height */
  }
  
  .item-page h1 {
    margin-top: 20px;
    margin-bottom: 40px;
    font-size: 2.5em;
    text-transform: uppercase;
    letter-spacing: 2px;
  }
  
  .back-button {
    display: inline-block;
    background-color: #1a1a1a;
    color: #ffffff;
    border: 1px solid #333333;
    padding: 12px 24px;
    margin-bottom: 20px;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1px;
    cursor: pointer;
    transition: all 0.3s ease;
    text-decoration: none;
  }
  
  .back-button:hover {
    background-color: #333333;
    border-color: #ffffff;
  }
  
  .back-button:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.5);
  }
  
  .item-details {
    max-width: 800px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
  }
  
  .item-image {
    width: 100%;
    max-width: 400px;
    height: auto;
    margin-bottom: 20px;
  }
  
  .item-info {
    width: 100%;
    max-width: 400px;
    text-align: left;
    padding: 0 20px;
  }
  
  .item-title {
    font-size: 2em;
    margin-bottom: 10px;
  }
  
  .item-price {
    font-size: 1.5em;
    margin-bottom: 20px;
  }
  
  .item-description {
    margin-bottom: 20px;
    line-height: 1.6;
  }
  
  .add-to-cart-button {
    background-color: #1DB954;
    color: white;
    border: none;
    padding: 15px 30px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .add-to-cart-button:hover {
    background-color: #1ED760;
  }
  
  .signup-flag {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #ff0000;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    z-index: 100;
    font-weight: bold;
    box-shadow: 0 2px 5px rgba(0,0,0,0.2);
  }
  
  .signup-flag:hover {
    transform: translateY(-3px);
    box-shadow: 0 4px 8px rgba(0,0,0,0.3);
  }
  
  .signup-flag span {
    display: block;
  }
  
  @media (min-width: 768px) {
    .item-details {
      justify-content: space-between;
    }
  
    .item-image, .item-info {
      width: 48%;
    }
  }

  .footer {
    display: none; /* Hide footer by default */
  }
  
  @media (min-width: 768px) {
    .footer {
      display: block; /* Show footer on larger screens */
      background-color: #111;
      color: white;
      padding: 10px 0;
      text-align: center;
    }
  }