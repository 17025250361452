.chatbot-page {
  padding: 20px;
  text-align: center;
  background-color: black;
  color: white;
  font-family: "Righteous", sans-serif;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.content {
  flex: 1;
  padding: 20px;
  padding-top: 80px;
}

h1 {
  margin-bottom: 20px;
  font-size: 2.5em;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.chat-container {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  background-color: #111111;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 70vh;
}

.chat-messages {
  flex-grow: 1;
  overflow-y: auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.message {
  max-width: 80%;
  padding: 10px 15px;
  margin-bottom: 10px;
  border-radius: 20px;
  word-wrap: break-word;
}

.user {
  align-self: flex-end;
  background-color: #1a1a1a;
}

.bot {
  align-self: flex-start;
  background-color: #333333;
}

.chat-input-form {
  display: flex;
  padding: 20px;
  background-color: #1a1a1a;
}

.chat-input {
  flex-grow: 1;
  padding: 10px;
  border: none;
  border-radius: 20px;
  background-color: #333333;
  color: white;
}

.chat-submit {
  margin-left: 10px;
  padding: 10px 20px;
  border: none;
  border-radius: 20px;
  background-color: #1DB954;
  color: white;
  cursor: pointer;
}

.chat-submit:disabled {
  background-color: #999999;
  cursor: not-allowed;
}

.loading-dots .dot {
  animation: pulse 1.4s infinite;
  display: inline-block;
}

.loading-dots .dot:nth-child(2) {
  animation-delay: 0.2s;
}

.loading-dots .dot:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes pulse {
  0% { opacity: 0.4; }
  50% { opacity: 1; }
  100% { opacity: 0.4; }
}