  /* src/components/MenuItem/MenuItem.css */
.menu-item-link {
    text-decoration: none;
  }
  
  .menu-item {
    color: white;
    font-size: 3rem;
    font-family: "Righteous", sans-serif;
    font-weight: 400;
    margin: 20px 0;
    -webkit-text-stroke: 2px black;
    transition: all 0.3s ease;
    cursor: pointer;
  }
  
  .menu-item:hover {
    text-shadow: 0 0 10px rgba(255, 255, 255, 0.5);
  }