/* src/components/MusicPage/MusicPage.css */
.music-page {
    padding: 20px;
    text-align: center;
    background-color: black;
    color: white;
    font-family: "Righteous", sans-serif;
    min-height: 100vh;
  }
  
  .spotify-embed-container {
    max-width: 800px;
    margin: 20px auto;
    padding: 0 20px;
  }
  
  h1 {
    margin-top: 60px; /* Adjust this value based on your Navigation bar height */
  }
  .footer {
    flex-shrink: 0;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    width: 100%;
  }