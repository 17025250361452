/* src/components/CollectionPage/CollectionPage.css */
.collection-page {
    background-color: black;
    color: white;
    font-family: "Righteous", sans-serif;
    min-height: 100vh;
    flex-direction: column;
    display: flex;
  }
  
  .content {
    padding: 20px;
    padding-top: 80px; /* Adjust this value based on your navbar height */
    text-align: center;
    padding-bottom: 60px; /* Adjust this value based on your footer height */
  }
  
  .collection-page h1 {
    margin-top: 20px;
    margin-bottom: 40px;
    font-size: 2.5em;
    text-transform: uppercase;
    letter-spacing: 2px;
  }
  
  .back-button {
    display: inline-block;
    background-color: #1a1a1a;
    color: #ffffff;
    border: 1px solid #333333;
    padding: 12px 24px;
    margin-bottom: 20px;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1px;
    cursor: pointer;
    transition: all 0.3s ease;
    text-decoration: none;
  }
  
  .back-button:hover {
    background-color: #333333;
    border-color: #ffffff;
  }
  
  .back-button:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.5);
  }
  
  #collection-component {
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .signup-flag {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #ff0000;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    z-index: 100;
    font-weight: bold;
    box-shadow: 0 2px 5px rgba(0,0,0,0.2);
  }
  
  .signup-flag:hover {
    transform: translateY(-3px);
    box-shadow: 0 4px 8px rgba(0,0,0,0.3);
  }
  
  .signup-flag span {
    display: block;
  }
  .footer {
    display: none; /* Hide footer by default */
  }
  
  @media (min-width: 768px) {
    .footer {
      display: block; /* Show footer on larger screens */
      background-color: #111;
      color: white;
      padding: 10px 0;
      text-align: center;
    }
  }

  #collection-component-1721071530263,
#collection-component-1721073255574 {
  margin-bottom: 60px;
}