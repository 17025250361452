/* src/components/TourPage/TourPage.css */
.tour-page {
    padding: 20px;
    text-align: center;
    background-color: black;
    color: white;
    min-height: 100vh;
    font-family: "Righteous", sans-serif;
  }
  
  .songkick-widget-container {
    margin-top: 20px;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
  }
  
  /* Ensure the widget is visible */
  .songkick-widget {
    display: block !important;
  }

  .footer {
    flex-shrink: 0;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    width: 100%;
  }