/* src/components/Navigation/Navigation.css */
.navigation {
    background-color: rgba(0, 0, 0, 0.8);
    padding: 10px 0;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    justify-content: center;
    display: flex;
  }
  
  .nav-item {
    color: white;
    text-decoration: none;
    margin: 0 15px;
    font-size: 1.2em;
    transition: color 0.3s ease;
    font-family: "Righteous", sans-serif;
  }
  
  .nav-item:hover {
    color: #ff0000;
  }